import React, { PureComponent } from 'react'
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts'
import './_chart.scss'

export default class Chart extends PureComponent {
  constructor (props) {
    super(props)
  }

  render () {
    return (
      <ResponsiveContainer width="100%" maxHeight="500px" minHeight="500px" height="100%">
        <LineChart width={500} height={300} data={this.props.data}>
          <CartesianGrid strokeDasharray="3 3"/>
          <XAxis dataKey="name" padding={{ left: 30, right: 30 }}/>
          <YAxis/>
          <Tooltip/>
          <Legend/>
          <Line className="chart__legend" type="monotone" dataKey="Principal Paid" stroke="#4063E1"
                activeDot={{ r: 5 }}/>
          <Line className="chart__legend" type="monotone" dataKey="Principal Remaining" stroke="#24AB4C"
                activeDot={{ r: 5 }}/>
          <Line className="chart__legend" type="monotone" dataKey="Interest Paid" stroke="#673AB7"
                activeDot={{ r: 5 }}/>
        </LineChart>
      </ResponsiveContainer>
    )
  }
}

import React from 'react'
import Layout from '../component/Layout/Layout'
import MortgageCalculator from '../component/MortgagaeCalculator/MortgageCalculator'
import AccurateEstimate from '../component/AccurateEstimate/AccurateEstimate'
import Seo from '../component/Seo'

const CalculatorPage = () => {
  return (
    <Layout>
      <Seo title='Mortgage Calculator' />
      <MortgageCalculator/>
      <AccurateEstimate/>
    </Layout>
  )
}

export default CalculatorPage
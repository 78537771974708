import React, { useState } from 'react'
import PaymentTab from './PaymentTab'
import AmortizationTab from './AmortizationTab'

const CalculatorTabs = ({
  principalAndInterest,
  privateMortgageInsurance,
  propertyTax,
  homeInsurance,
  hoaDues,
  monthlyPayment,
  estimatedPayoffYear,
  estimatedPayoffMonth,
  updateHomeInsurance,
  updateHoaDues,
  loanTerm,
  homePrice,
  interestRate,
  downPaymentAmount,
}) => {
  const [tab, setTab] = useState('payment')
  const mainClasses = `mortgage-calculator__tabs ${tab}`

  const onChangeActiveTab = (name) => {
    setTab(name)
  }

  return (
    <>
      <div className={mainClasses}>
        <div className="mortgage-calculator__tabs__head">
          <button
            className={`mortgage-calculator__tabs__head__item ${tab === 'payment' ? tab : ''}`}
            onClick={() => onChangeActiveTab('payment')}
          >
            Payment breakdown
          </button>
          <button
            className={`mortgage-calculator__tabs__head__item ${tab === 'amortization' ? tab : ''}`}
            onClick={() => onChangeActiveTab('amortization')}
          >
            Amortization schedule
          </button>
        </div>

        <div className="mortgage-calculator__tabs__content">
          <PaymentTab
            principalAndInterest={principalAndInterest}
            privateMortgageInsurance={privateMortgageInsurance}
            propertyTax={propertyTax}
            homeInsurance={homeInsurance}
            hoaDues={hoaDues}
            monthlyPayment={monthlyPayment}
            estimatedPayoffYear={estimatedPayoffYear}
            estimatedPayoffMonth={estimatedPayoffMonth}
            updateHomeInsurance={updateHomeInsurance}
            updateHoaDues={updateHoaDues}
          />
          <AmortizationTab
            loanTerm={loanTerm}
            interestRate={interestRate}
            homePrice={homePrice}
            downPaymentAmount={downPaymentAmount}
          />
        </div>
      </div>
    </>
  )
}

export default CalculatorTabs
import React from 'react'
import './accurate-estimate.scss'
import picture from '../../images/accurate-estimate/picture.png'
import { Link } from 'gatsby'

const AccurateEstimate = () => {
  return (
    <section className="accurate-estimate">
      <div className="accurate-estimate__inner">
        <div className="accurate-estimate__container">
          <div className="accurate-estimate__left">
            <span className="accurate-estimate__tagline tagline">See more</span>
            <h2 className="accurate-estimate__headline">Get a more accurate estimate</h2>
            <div className="accurate-estimate__image-container">
              <img src={picture} alt="newsletter" className="accurate-estimate__image"/>
            </div>
          </div>
          <div className="accurate-estimate__right">
            <p className="accurate-estimate__text">
              Get pre-qualified by a local lender to see an even more accurate estimate of your monthly mortgage payment.
              You'll also be ready to act fast when you find the perfect home.
            </p>
            <Link className="accurate-estimate__button" to="/develop">
              Get Pre-qualified
            </Link>
          </div>
        </div>
      </div>
    </section>
  )
}
export default AccurateEstimate
import React, { useEffect, useState } from 'react'
import './_calculator.scss'
import CalculatorTabs from './CalculatorTabs'
import arrow from '../../images/calculator/arrow.svg'
import NumberFormat from 'react-number-format'

const MortgageCalculator = () => {

  useEffect(() => {
    setPrincipalAndInterest(proc_principalAndInterest)
    setMonthlyPayment(monthlyPaymentSum)
    setPrivateMortgageInsurance(proc_pmiPayment)
    setHoaDues(proc_hoa)
    setPropertyTax(proc_propertyTax)
  })

  const fixedValue = (value) => parseFloat(value.toFixed(2))

  const getCurrentYear = () => {
    const date = new Date()
    return date.getUTCFullYear()
  }
  const getCurrentMonth = () => {
    const date = new Date()
    return date.toLocaleString('en', { month: 'long' })
  }

  const updateHomeInsurance = (value) => setHomeInsurance(value)

  const updateHoaDues = (value) => setHoaDues(value)

  const [showMore, setShowMore] = useState('mortgage-calculator__data__more')
  const [homePrice, setHomePrice] = useState(100000)
  const [downPaymentAmount, setDownPaymentAmount] = useState(50000)
  const [downPaymentPercent, setDownPaymentPercent] = useState(50)
  const [interestRate, setInterestRate] = useState(2.217)
  const [loanTerm, setLoanTerm] = useState(10)
  const [propertyTaxPercent, setPropertyTaxPercent] = useState(1.04)

  const [principalAndInterest, setPrincipalAndInterest] = useState(0)
  const [privateMortgageInsurance, setPrivateMortgageInsurance] = useState(0)
  const [propertyTax, setPropertyTax] = useState(0)
  const [homeInsurance, setHomeInsurance] = useState(600)
  const [hoaDues, setHoaDues] = useState(0)
  const [estimatedPayoffYear, setEstimatedPayoffYear] = useState(getCurrentYear() + loanTerm)
  const [estimatedPayoffMonth] = useState(getCurrentMonth)
  const [monthlyPayment, setMonthlyPayment] = useState(0)

  useEffect(() => {
    if (downPaymentPercent >= 100) {
      setDownPaymentPercent(100)
      setDownPaymentAmount((homePrice / 100) * downPaymentPercent)
    }
  }, [downPaymentPercent, downPaymentAmount])

  useEffect(() => {
    setDownPaymentAmount(downPaymentAmount)
  }, [downPaymentAmount])

  useEffect(() => {
    if (interestRate > 999) {
      setInterestRate(999)
    }
  }, [interestRate])

  const changePaymentPercent = (value) => {
    value = Number(value)
    setDownPaymentPercent(value)
    setDownPaymentAmount((homePrice / 100) * value)
  }

  const changePaymentAmount = (value) => {
    value = Number(value)
    setDownPaymentAmount(value)
    const paymentPercent = fixedValue((100 * value) / homePrice)
    setDownPaymentPercent(paymentPercent)
  }

  const changeHomePrice = (value) => {
    value = Number(value)
    if (value <= 0) {
      value = 1
      setHomePrice(value)
      const downPaymentAmountValue = fixedValue((value / 100) * downPaymentPercent)
      setDownPaymentAmount(downPaymentAmountValue)
    } else {
      setHomePrice(value)
      const downPaymentAmountValue = fixedValue((value / 100) * downPaymentPercent)
      setDownPaymentAmount(downPaymentAmountValue)
    }
  }

  const changeState = (value) => {
    value = parseFloat(value)
    setPropertyTaxPercent(value)
  }

  const changePropertyTaxPercent = (value) => {
    value = parseFloat(value)
    setPropertyTaxPercent(fixedValue(value))
  }

  const changeLoanProgram = (e) => {
    const interestRateData = parseFloat(e.target.value)
    const loanTermData = parseInt(e.target.dataset.term)
    setInterestRate(interestRateData)
    setLoanTerm(loanTermData)
    setEstimatedPayoffYear(getCurrentYear() + loanTermData)
  }

  const changeInterestRage = (value) => {
    value = parseFloat(value)
    setInterestRate(value)
  }

  const changeHomeInsurance = (value) => {
    value = parseFloat(value)
    setHomeInsurance(value)
  }

  const changeHoaDues = (value) => {
    value = parseFloat(value)
    setHoaDues(value)
  }

  function monthlyPaymentFromPrincipal (monthlyInterestRate, loanTerm, principal) {
    return monthlyInterestRate * principal * Math.pow((1 + monthlyInterestRate), loanTerm) / (Math.pow((1 + monthlyInterestRate), loanTerm) - 1)
  }

  function getPMIPercent (downPaymentPercent) {
    if (downPaymentPercent < 0.05) {
      return 0.0130
    } else if (downPaymentPercent < 0.10) {
      return 0.0068
    } else if (downPaymentPercent < 0.15) {
      return 0.0051
    } else if (downPaymentPercent < 0.20) {
      return 0.0036
    } else {
      return 0
    }
  }

  const monthlyInterestRate = Number(interestRate) / 1200
  const loanTermInMonth = loanTerm * 12
  const principalAmount = Number(homePrice) - Number(downPaymentAmount)
  const proc_principalAndInterest = monthlyPaymentFromPrincipal(monthlyInterestRate, loanTermInMonth, principalAmount)
  const proc_pmiPayment = principalAmount * getPMIPercent(downPaymentAmount / homePrice) / 12
  const proc_propertyTax = homePrice * propertyTaxPercent / 1200
  const proc_monthlyHomeInsurance = homeInsurance / 12
  const proc_hoa = hoaDues
  const monthlyPaymentSum = proc_principalAndInterest + proc_pmiPayment + proc_propertyTax + proc_monthlyHomeInsurance + proc_hoa

  return (
    <section className="mortgage-calculator">
      <div className="mortgage-calculator__inner">
        <div className="mortgage-calculator__head">
          <div className="mortgage-calculator__head-left">
            <p className="mortgage-calculator__subtitle">BY HOME PRICE</p>
            <h1 className="mortgage-calculator__title">Mortgage Calculator</h1>
          </div>
          <div className="mortgage-calculator__head-right">
            <label className="mortgage-calculator__state-label" htmlFor="mortgage-calculator__state">Choose your
              state</label>
            <select
              className="mortgage-calculator__state"
              name="state"
              id="mortgage-calculator__state"
              onChange={(e) => changeState(e.target.value)}
            >
              <option value="1.04">Alaska</option>
              <option value="0.6">Colorado</option>
              <option value="0.33">Alabama</option>
              <option value="0.74">California</option>
              <option value="0.97">Florida</option>
            </select>
          </div>
        </div>
        <div className="mortgage-calculator__body">
          <ul className="mortgage-calculator__data">
            <li className="mortgage-calculator__data__item">
              <label htmlFor="hp-loan-amount" className="mortgage-calculator__data__title">Home Price</label>
              <div className="mortgage-calculator__data__input-wrap">
                <NumberFormat
                  thousandSeparator={true}
                  value={homePrice}
                  className="mortgage-calculator__data__input"
                  allowNegative={false}
                  isNumericString={true}
                  onFocus={(e) => e.target.select()}
                  onValueChange={(values) => {
                    const { value } = values
                    changeHomePrice(value)
                  }}
                />
                <span className="dollar">$</span>
              </div>
              <div className="mortgage-calculator__data__range">
                <input type="range" name="price" id="hp-loan-amount-range" value={homePrice || ''}
                       onChange={(e) => changeHomePrice(e.target.value)} min="80000"
                       max="5000000" className="mortgage-calculator__data__range-input"/>
                <p className="mortgage-calculator__data__range-text">$80K</p>
                <p className="mortgage-calculator__data__range-text">$3M+</p>
              </div>
            </li>
            <li className="mortgage-calculator__data__item">
              <label htmlFor="hp-down-payment-amount" className="mortgage-calculator__data__title">Down Payment</label>
              <div className="mortgage-calculator__data__input-wrap">
                <div className="mortgage-calculator__data__input-wrap__left">
                  <NumberFormat
                    thousandSeparator={true}
                    value={Math.round(downPaymentAmount)}
                    className="mortgage-calculator__data__input"
                    id="hp-down-payment-amount"
                    name="hp-down-payment-amount"
                    allowNegative={false}
                    isNumericString={true}
                    onFocus={(e) => e.target.select()}
                    onValueChange={(values) => {
                      const { value } = values
                      changePaymentAmount(value)
                    }}
                  />
                  <span className="dollar">$</span>
                </div>
                <div className="mortgage-calculator__data__input-wrap__right">
                  <NumberFormat
                    thousandSeparator={true}
                    value={downPaymentPercent}
                    className="mortgage-calculator__data__input"
                    id="hp-down-payment-percent"
                    name="hp-down-payment-percent"
                    allowNegative={false}
                    isNumericString={true}
                    onFocus={(e) => e.target.select()}
                    onValueChange={(values) => {
                      const { value } = values
                      changePaymentPercent(value)
                    }}
                  />
                  <span className="percent">%</span>
                </div>
              </div>
            </li>
            <li className="mortgage-calculator__data__item">
              <label htmlFor="hp-down-payment-amount" className="mortgage-calculator__data__title">Interest Rate</label>
              <div className="mortgage-calculator__data__input-wrap">
                <NumberFormat
                  thousandSeparator={true}
                  value={interestRate}
                  name="hp-interest-rate"
                  id="hp-interest-rate"
                  className="mortgage-calculator__data__input"
                  allowNegative={false}
                  isNumericString={true}
                  onFocus={(e) => e.target.select()}
                  onValueChange={(values) => {
                    const { value } = values
                    changeInterestRage(value)
                  }}
                />
                <span className="percent">%</span>
              </div>
            </li>
            <li className="mortgage-calculator__data__item">
              <span
                className="mortgage-calculator__data__title"><span>Loan Program</span> <span>years fixed</span></span>
              <div className="mortgage-calculator__data__radio">
                <div className="mortgage-calculator__data__radio__item">
                  <input
                    type="radio"
                    name="hp-loanTermInMonths"
                    value={1.903}
                    checked={loanTerm === 10}
                    data-term={10}
                    id="loanTermInMonths10"
                    className="mortgage-calculator__data__radio__item-input"
                    onChange={(e) => changeLoanProgram(e)}
                  />
                  <label htmlFor="loanTermInMonths10">10</label>
                </div>
                <div className="mortgage-calculator__data__radio__item">
                  <input
                    type="radio"
                    name="hp-loanTermInMonths"
                    value={2.069}
                    data-term={15}
                    id="loanTermInMonths15"
                    className="mortgage-calculator__data__radio__item-input"
                    onChange={(e) => changeLoanProgram(e)}
                  />
                  <label htmlFor="loanTermInMonths15">15</label>
                </div>
                <div className="mortgage-calculator__data__radio__item">
                  <input
                    type="radio"
                    name="hp-loanTermInMonths"
                    value={2.574}
                    data-term={20}
                    id="loanTermInMonths20"
                    className="mortgage-calculator__data__radio__item-input"
                    onChange={(e) => changeLoanProgram(e)}
                  />
                  <label htmlFor="loanTermInMonths20">20</label>
                </div>
                <div className="mortgage-calculator__data__radio__item">
                  <input
                    type="radio"
                    name="hp-loanTermInMonths"
                    value={2.732}
                    data-term={30}
                    id="loanTermInMonths30"
                    className="mortgage-calculator__data__radio__item-input"
                    onChange={(e) => changeLoanProgram(e)}
                  />
                  <label htmlFor="loanTermInMonths30">30</label>
                </div>
              </div>
            </li>

            <ul className={showMore}>
              <button className="mortgage-calculator__data__more__button"
                      onClick={() => setShowMore('mortgage-calculator__data__more mortgage-calculator__data__more--active')}>Show
                additional options <img
                  src={arrow} alt="arrow"/></button>
              <li className="mortgage-calculator__data__item">
                <label htmlFor="hp-property-tax" className="mortgage-calculator__data__title">Property Tax <span
                  className="mortgage-calculator__data__title-month">/ month</span></label>
                <div className="mortgage-calculator__data__input-wrap">
                  <NumberFormat
                    thousandSeparator={true}
                    value={propertyTaxPercent}
                    name="hp-property-tax"
                    id="hp-property-tax"
                    className="mortgage-calculator__data__input"
                    allowNegative={false}
                    isNumericString={true}
                    onFocus={(e) => e.target.select()}
                    onValueChange={(values) => {
                      const { value } = values
                      changePropertyTaxPercent(value)
                    }}
                  />
                  <span className="percent">%</span>
                </div>
              </li>
              <li className="mortgage-calculator__data__item">
                <label htmlFor="hp-home-insurance" className="mortgage-calculator__data__title">Home Insurance <span
                  className="mortgage-calculator__data__title-month">/ month</span></label>
                <div className="mortgage-calculator__data__input-wrap">
                  <NumberFormat
                    thousandSeparator={true}
                    value={homeInsurance}
                    name="hp-home-insurance"
                    id="hp-home-insurance"
                    className="mortgage-calculator__data__input"
                    allowNegative={false}
                    isNumericString={true}
                    onFocus={(e) => e.target.select()}
                    onValueChange={(values) => {
                      const { value } = values
                      changeHomeInsurance(value)
                    }}
                  />
                  <span className="dollar">$</span>
                </div>
              </li>
              <li className="mortgage-calculator__data__item">
                <label htmlFor="hp-hoa" className="mortgage-calculator__data__title">HOA Dues <span
                  className="mortgage-calculator__data__title-month">/ month</span></label>
                <div className="mortgage-calculator__data__input-wrap">
                  <NumberFormat
                    thousandSeparator={true}
                    value={hoaDues}
                    name="hp-hoa"
                    id="hp-hoa"
                    className="mortgage-calculator__data__input"
                    allowNegative={false}
                    isNumericString={true}
                    onFocus={(e) => e.target.select()}
                    onValueChange={(values) => {
                      const { value } = values
                      changeHoaDues(value)
                    }}
                  />
                  <span className="dollar">$</span>
                </div>
              </li>
              <button
                className="mortgage-calculator__data__more__button mortgage-calculator__data__more__button--hide"
                onClick={() => setShowMore('mortgage-calculator__data__more')}>
                Hide additional options
                <img src={arrow} alt="arrow"/>
              </button>
            </ul>
          </ul>
          <CalculatorTabs
            principalAndInterest={principalAndInterest}
            privateMortgageInsurance={privateMortgageInsurance}
            propertyTax={propertyTax}
            homeInsurance={homeInsurance}
            hoaDues={hoaDues}
            monthlyPayment={monthlyPayment}
            estimatedPayoffYear={estimatedPayoffYear}
            estimatedPayoffMonth={estimatedPayoffMonth}
            updateHomeInsurance={updateHomeInsurance}
            updateHoaDues={updateHoaDues}
            loanTerm={loanTerm}
            interestRate={interestRate}
            homePrice={homePrice}
            downPaymentAmount={downPaymentAmount}
          />
        </div>
      </div>
    </section>
  )
}

export default MortgageCalculator
import React from 'react'
import Chart from '../Chart/Chart'

const AmortizationTab = ({ loanTerm, interestRate, homePrice, downPaymentAmount }) => {
  const monthlyInterestRate = interestRate / 1200
  const loanTermInMonth = loanTerm * 12
  const loanAmount = homePrice - downPaymentAmount
  const monthlyPayment = monthlyInterestRate * loanAmount * Math.pow(1 + monthlyInterestRate, loanTermInMonth) / (Math.pow((1 + monthlyInterestRate), loanTermInMonth) - 1)
  let totalInterest = 0
  let totalPrincipal = 0
  let principalRemaining = loanAmount

  let month
  let interest, principal

  const data = [{
    name: 'Year 0',
    'Principal Remaining': Math.round(loanAmount),
    'Principal Paid': 0,
    'Interest Paid': 0,
  }]
  let index = 0
  for (month = 1; month <= loanTermInMonth; month++) {

    let principalInterest = monthlyPayment
    interest = principalRemaining * monthlyInterestRate
    totalInterest += interest
    principal = principalInterest - interest
    totalPrincipal += principal

    principalRemaining = principalRemaining - principal

    if (month % 12 === 0) {
      index++
      const obj = {
        name: 'Year ' + index,
        'Principal Remaining': Math.round(principalRemaining),
        'Principal Paid': Math.round(totalPrincipal),
        'Interest Paid': Math.round(totalInterest),
      }

      data.push(obj)
    }
  }

  return (
    <div className="mortgage-calculator__amortization">
      <Chart data={data}/>
    </div>
  )
}

export default AmortizationTab